<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsMeta.total_count"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
      >
        <template v-for="slot of Object.keys($slots)" v-slot:[slot]>
          <slot :name="slot" />
        </template>

        <template v-slot:top>
          <slot name="top">
            <FormToolbar :title="title" :with-search="withSearch" @search="(val) => (search = val)" />
          </slot>
        </template>

        <template v-for="col in headers.map((head) => head.value)" v-slot:[`header.${col}`]="{ header }">
          <slot :name="`header.${col}`" :header="header">{{ header.text }}</slot>
        </template>

        <template v-for="col in headers.map((head) => head.value)" v-slot:[`item.${col}`]="{ item }">
          <slot :name="`item.${col}`" :item="item">{{ item[col] }}</slot>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import FormToolbar from '@/components/common/forms/FormToolbar'
import sortUtils from '@/mixins/sortUtils'
import awaitingSearch from '@/mixins/constructor/awaitingSearch'

export default {
  name: 'TableForm',
  components: { FormToolbar },

  mixins: [sortUtils, awaitingSearch],

  props: {
    title: {
      type: String,
      required: true,
    },

    headers: {
      type: Array,
      required: true,
    },

    withSearch: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array,
      required: true,
    },

    itemsMeta: {
      type: Object,
      required: true,
    },

    loadFunction: {
      type: Function,
      required: true,
    },

    payload: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      loading: false,
      options: {},
      search: '',
    }
  },

  watch: {
    options: {
      async handler() {
        await this.paginateTo()
      },
      deep: true,
    },
  },

  methods: {
    async paginateTo() {
      this.loading = true
      const payload = {
        ...this.payload,
        page: this.options.page,
        limit: this.options.itemsPerPage,
        sort: this.sortObject,
        search: this.search,
      }
      try {
        await this.loadFunction(payload)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
