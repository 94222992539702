<template>
  <TableForm
    title="Клієнти"
    :headers="headers"
    :items="clients"
    :items-meta="clientsMeta"
    :load-function="loadClients"
    :payload="payload"
    with-search
  >
    <template v-slot:item.user_id="{ item }">
      <router-link
        :to="{
          name: 'ClientCard',
          params: {
            id: currentCompanyId.toString(),
            clientId: item.user_id.toString(),
          },
        }"
        class="text-decoration-none"
      >
        {{ item.user_id }}
      </router-link>
    </template>

    <template v-slot:item.full_name="{ item }">
      <router-link
        :to="{
          name: 'ClientCard',
          params: {
            id: currentCompanyId.toString(),
            clientId: item.user_id.toString(),
          },
        }"
        class="text-decoration-none"
      >
        {{ item.first_name }} {{ item.last_name }}
      </router-link>
    </template>

    <template v-slot:item.birthday="{ item }">{{ item.birthday | getShortDate }}</template>

    <template v-slot:item.is_active_client="{ item }">
      <CircleIndicator :is-on="item.is_active_client" />
    </template>

    <template v-slot:item.payment_count="{ item }">{{ item.payment_count.toLocaleString() }}</template>
    <template v-slot:item.payment_amount="{ item }">{{ item.payment_amount.toLocaleString() }}</template>

    <template v-slot:item.last_payment_date="{ item }">
      {{ item.last_payment_date | getShortDate }} <sup>{{ item.last_payment_date | getShortTime }}</sup>
    </template>

    <template v-slot:item.actions="{ item }">
      <TableActions no-edit-button no-delete-button @view="navigateToClientCard(item)">
        <template v-slot:prepend>
          <BonusPayroll :user-id="item.user_id">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-tooltip :open-on-focus="false" bottom>
                  <template v-slot:activator="{ on: ttOn, attrs: ttAttrs }">
                    <button class="v-icon v-icon--link mdi mr-1" v-bind="ttAttrs" v-on="ttOn">
                      <img src="@/assets/images/cashback.svg" alt="Cashback icon" width="16" height="16" />
                    </button>
                  </template>
                  <span>Нарахувати бонуси</span>
                </v-tooltip>
              </span>
            </template>
          </BonusPayroll>
        </template>
      </TableActions>
    </template>
  </TableForm>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TableForm from '@/components/common/forms/TableForm'
import convertDate from '@/mixins/convertDate'
import CircleIndicator from '@/components/common/CircleIndicator'
import TableActions from '@/components/common/forms/TableActions'
import BonusPayroll from '@/components/marketing/clients/BonusPayroll.vue'
import sortUtils from '@/mixins/sortUtils'

export default {
  name: 'ClientsList',

  components: { BonusPayroll, TableActions, CircleIndicator, TableForm },

  mixins: [convertDate, sortUtils],

  data() {
    return {
      payload: null,
      headers: [
        { text: 'ID користувача', align: 'left', sortable: true, value: 'user_id' },
        { text: 'Імʼя', align: 'left', sortable: true, value: 'full_name', sortFields: ['last_name', 'first_name'] },
        { text: 'Дата народження', align: 'center', sortable: true, value: 'birthday' },
        { text: 'Активний', align: 'center', sortable: false, value: 'is_active_client' },
        { text: 'Кількість платежів', align: 'right', sortable: true, value: 'payment_count' },
        { text: 'Сума', align: 'right', sortable: true, value: 'payment_amount' },
        { text: 'Останній платіж', align: 'left', sortable: true, value: 'last_payment_date' },
        { text: '', align: 'center', sortable: false, value: 'actions', width: '125px' },
      ],
    }
  },

  created() {
    this.initialize()
  },

  computed: {
    ...mapState('users', ['clients', 'clientsMeta']),

    currentCompanyId() {
      return this.$route.params.id
    },
  },

  methods: {
    ...mapActions('users', ['loadClients']),

    initialize() {
      this.payload = {
        company: this.currentCompanyId,
      }
    },

    navigateToClientCard(item) {
      this.$router.push({
        name: 'ClientCard',
        params: {
          id: this.currentCompanyId.toString(),
          clientId: item.user_id.toString(),
        },
      })
    },
  },
}
</script>
